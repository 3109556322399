import { Component, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// import { slider, transformer, fader, stepper } from '@angular/animations'

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // animations: [
  //   fader,
  //   slider,
  // ]
})
export class AppComponent implements OnDestroy {
  title = 'zenith';
  subscription: Subscription;

  constructor(private router: Router) {
    this.subscription = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh = !router.navigated;
        }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

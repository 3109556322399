import { Component, OnInit } from "@angular/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { ControlRoutes } from "../../models/constants";
import { AuthCred } from "../../models/auth-cred";
import { AuthService } from "../../services/auth/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition("void => *, * => void", [animate("300ms ease-in")]),
    ]),
    trigger("fadeSlow", [
      state("void", style({ opacity: 0 })),
      transition("void => *, * => void", [animate("1000ms ease-in")]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  todayDate: Date = new Date();
  errorMessage: string = "";
  submitting: boolean = false;
  timeoutMessage: string = "";
  rememberMe: boolean;
  submitVisible: boolean = true;

  ac: AuthCred = {
    //userName: "test",
    //password: "zenithtest1"
    userName: "",
    password: "",
  };

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.timeoutMessage = this.authService.loginTimoutMessage;

    if (this.authService.isLoggedIn) {
      console.log("SSO already logged in");
      this.authService.loginSSO();
    }

    if (localStorage.getItem("rememberMe") == "true") {
      this.rememberMe = true;
      this.ac.userName = localStorage.getItem("userName");
    }
  }

  onSubmit(form: NgForm) {
    this.submitting = true;
    this.submitVisible = false;
    this.errorMessage = "";

    this.authService.login(this.ac).subscribe(
      (success) => {
        if (success) {
          this.submitVisible = true;
          this.submitting = false;

          if (this.rememberMe) {
            localStorage.setItem("userName", this.ac.userName);
            localStorage.setItem("rememberMe", String(this.rememberMe));
          } else {
            localStorage.setItem("userName", "");
            localStorage.setItem("rememberMe", "false");
          }

          this.router.navigateByUrl(ControlRoutes.Approval);
        }
      },
      (err) => {
        this.errorMessage = "Login Failed";
        this.submitVisible = true;
        this.submitting = false;
      }
    );
  }
}

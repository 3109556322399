<table class="table table-striped table-sm" [@fade]>
    <thead>
        <tr>
            <th scope="col">

              <!-- <i-feather name="thumbs-up" style="color: var(--success);" class="ml-2" (click)="onApproveAllClick()" data-toggle="tooltip" data-placement="bottom" title="Tooltip on top">
              </i-feather>
              <i-feather name="pause-circle" style="color: var(--warning); margin-left: 18px;" (click)="onHoldAllClick()">
              </i-feather>
              <i-feather name="thumbs-down" style="color: var(--danger); margin-left: 18px;" (click)="onRejectAllClick()">
              </i-feather>
              <i-feather name="edit3" class="rounded" style="color: var(--primary); margin-left: 19px;" (click)="onCommentAllClick()">
              </i-feather> -->

            </th>
            <th scope="col">Description</th>
            <th scope="col">Reference</th>
            <th scope="col">Date</th>
            <th scope="col">Net</th>
            <th scope="col">Info</th>
            <th scope="col">Last Approver</th>
            <th scope="col">Comments</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let doc of documents" [style.display]="doc.completed ? 'none' : ''">
            <th scope="row" style="white-space: nowrap;">
                <!-- <input name="{{doc.id}}" [checked]="doc.selected" (click)="onItemSelect(doc)" type="checkbox">&nbsp; [checked]="doc.selected" (click)="onItemSelect(doc)" -->
                <!-- <i-feather (click)="onApproveClick(doc)" name="check" style="color: var(--success);"
                    aria-haspopup="true" aria-expanded="false" data-toggle="modal" data-target="#approveModal">
                </i-feather>&nbsp; -->

                <ng-template [ngIf]="doc.isLocked">
                  <i-feather name="lock" style="color: var(--dark);" >
                  </i-feather>
                  <span class="font-weight-normal">  {{ doc.conflict_user }}</span>
                </ng-template>

                <ng-template [ngIf]="doc.isOnHold">
                  <i-feather name="pause-circle" style="color: var(--dark);" >
                  </i-feather>
                </ng-template>


                <ng-template [ngIf]="!doc.isLocked && !(doc.isOnHold && !doc.isCanRemoveHold)">

                  <ng-template [ngIf]="!doc.isOnHold">
                    <ng-template [ngIf]="doc.action === 'Approve'">
                      <button class="btn btn-success btn-sm" (click)="onApproveClick(doc)" >
                        <i-feather  name="thumbs-up" class="rounded" style="background-color: var(--success); color: white;" >
                        </i-feather>
                      </button>
                    </ng-template>
                    <ng-template [ngIf]="doc.action != 'Approve'">
                      <button class="btn btn-light btn-sm" (click)="onApproveClick(doc)">
                        <i-feather name="thumbs-up" style="color: var(--success);" >
                        </i-feather>
                      </button>
                    </ng-template>
                  </ng-template>

                  <ng-template [ngIf]="!doc.isOnHold">
                    <ng-template [ngIf]="doc.action === 'Hold'">
                      <button class="btn btn-warning btn-sm" (click)="onHoldClick(doc)">
                        <i-feather name="pause-circle" class="rounded" style="background-color: var(--warning); color: white;" >
                        </i-feather>
                      </button>
                    </ng-template>
                    <ng-template [ngIf]="doc.action != 'Hold'">
                      <button class="btn btn-light btn-sm" (click)="onHoldClick(doc)">
                        <i-feather name="pause-circle" style="color: var(--warning);" >
                        </i-feather>
                      </button>
                    </ng-template>
                  </ng-template>

                  <ng-template [ngIf]="doc.isOnHold">
                    <ng-template [ngIf]="doc.action === 'Un-Hold'">
                      <button class="btn btn-warning btn-sm" (click)="onUnHoldClick(doc)">
                        <i-feather name="play-circle" class="rounded" style="background-color: var(--warning); color: white;" >
                        </i-feather>
                      </button>
                    </ng-template>
                    <ng-template [ngIf]="doc.action != 'Un-Hold'">
                      <button class="btn btn-light btn-sm" (click)="onUnHoldClick(doc)">
                        <i-feather name="play-circle" style="color: var(--warning);" >
                        </i-feather>
                      </button>
                    </ng-template>
                  </ng-template>

                  <ng-template [ngIf]="doc.action === 'Reject'">
                    <button class="btn btn-danger btn-sm" (click)="onRejectClick(doc)">
                      <i-feather name="thumbs-down" class="rounded" style="background-color: var(--danger); color:white;" >
                      </i-feather>
                    </button>
                  </ng-template>
                  <ng-template [ngIf]="doc.action != 'Reject'">
                    <button class="btn btn-light btn-sm" (click)="onRejectClick(doc)">
                      <i-feather name="thumbs-down" style="color: var(--danger);" >
                      </i-feather>
                    </button>
                  </ng-template>

                  <ng-template [ngIf]="doc.action === 'Comment'">
                    <button class="btn btn-primary btn-sm" (click)="onCommentClick(doc)">
                      <i-feather name="edit3" class="rounded" style="background-color: var(--primary); color: white;" >
                      </i-feather>
                    </button>
                  </ng-template>
                  <ng-template [ngIf]="doc.action != 'Comment'">
                    <button class="btn btn-light btn-sm" (click)="onCommentClick(doc)">
                      <i-feather name="edit3" class="rounded" style="color: var(--primary);" >
                      </i-feather>
                    </button>
                  </ng-template>


                </ng-template>
                &nbsp;

                <!-- <i-feather (click)="onHoldClick(doc)" name="pause-circle" style="color: var(--warning);"
                    aria-haspopup="true" aria-expanded="false" data-toggle="modal" data-target="#holdModal"></i-feather>&nbsp;

                <i-feather (click)="onRejectClick(doc)" name="trash" style="color: var(--danger);" aria-haspopup="true"
                    aria-expanded="false" data-toggle="modal" data-target="#rejectModal"></i-feather>&nbsp;

                <i-feather (click)="onCommentClick(doc)" name="edit3" style="color: var(--primary);"
                    aria-haspopup="true" aria-expanded="false" data-toggle="modal" data-target="#commentModal">
                </i-feather>&nbsp; -->


                <i-feather name="image" style="color: var(--dark); cursor: pointer;" href="#" [routerLink]="['/documents', doc.docid, 'imageview']">
                </i-feather>&nbsp;

                <!-- <i-feather name="file-text" style="color: var(--dark);" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                </i-feather>
                <div class="dropdown-menu">
                    <a class="dropdown-item" href="#" routerLink="/documents">Journal Report</a>
                    <a class="dropdown-item" href="#" routerLink="/documents">Entity History Report</a>
                </div> -->

                <div *ngIf="doc.action" class="mt-2 mb-2">
                    <div class="form-group">
                      <ng-template [ngIf]="doc.action === 'Comment'">
                        <label class="font-weight-normal" for="comment">Comments(required):</label>
                      </ng-template>
                      <ng-template [ngIf]="doc.action != 'Comment'">
                        <label class="font-weight-normal" for="comment">Comments(optional):</label>
                      </ng-template>

                      <textarea name="comment" id="" cols="10" rows="1" class="form-control" [(ngModel)]="doc.comment"></textarea>
                    </div>
                </div>

            </th>

            <td>{{ doc.description }}</td>
            <td class="text-right">{{ doc.full_reference }}</td>
            <td>{{ doc.transaction_date | date:'shortDate'}}</td>
            <td class="text-right">{{ doc.net | currency }}</td>
            <td>{{ doc.zen_journalname }}</td>
            <td class="text-center">{{ doc.last_approver }}</td>
            <td>{{ doc.approval_comment }}</td>

        </tr>

    </tbody>
</table>

<nav class="navbar sticky-top navbar-light border-0 pl-0 pr-0" [@fade]='state' (click)='animateMe()'>
    <div class="mr-auto bg-white rounded-sm">

      <ng-template [ngIf]="actionItems.length < 1 ">
        <span style="opacity: .5;">
          <button class="btn btn-success btn-sm border-dark" disabled>
            <i-feather name="check"></i-feather><span class="d-sm-inline"> Submit</span>
          </button>&nbsp;
          <button class="btn btn-secondary btn-sm" disabled>
            <i-feather name="x"></i-feather><span class="d-sm-inline"> Clear</span>
          </button>&nbsp;
        </span>
        <button class="btn btn-danger btn-sm" (click)="onCancelClick()">
          <i-feather name="x"></i-feather><span class="d-sm-inline"> Cancel</span>
        </button>&nbsp;

      </ng-template>

      <ng-template [ngIf]="actionItems.length > 0 ">
        <span>
          <button class="btn btn-success btn-sm border-dark" (click)="onSubmitClick()">
            <i-feather name="check"></i-feather><span class="d-sm-inline"> Submit</span>
          </button>&nbsp;
          <button class="btn btn-secondary btn-sm" (click)="onClearClick()">
            <i-feather name="x"></i-feather><span class="d-sm-inline"> Clear</span>
          </button>&nbsp;
          <button class="btn btn-danger btn-sm" (click)="onCancelClick()">
            <i-feather name="x"></i-feather><span class="d-sm-inline"> Cancel</span>
          </button>&nbsp;


          {{ actionItems.length }} Action(s)
        </span>
      </ng-template>

    </div>
</nav>

<app-navbar></app-navbar>

<form #form="ngForm" (ngSubmit)="onSubmit()" novalidate>

  <div class="container mtb-6 p-4">
    <h4>Approval</h4>

    <div *ngIf="timeoutMessage" class="alert alert-info"><i-feather name="info"></i-feather>&nbsp;{{timeoutMessage}}</div>

    <div #accordion class="accordion" id="accordionExample">
      <div class="card">
        <div class="card-header fs-6" id="headingOne">
          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" (click)="handleCollapse()">
            <i-feather name="filter"></i-feather>&nbsp;&nbsp;Filter Options
          </button>
        </div>

        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
          <div class="card-body">

            <div class="form-group">
              <label for="clientSelect">Client</label>
              <ng-select [(ngModel)]="documentFilter.client" name="clientSelect">
                <ng-option *ngFor="let client of clients" [value]="client.client">{{ client.client }} {{client.client_name}}</ng-option>
              </ng-select>
            </div>

            <div class="form-group">
              <label for="journalPayrollSelect">Journal/Payroll</label>
              <select class="form-control" id="journalPayrollSelect" name="journalPayrollSelect" [(ngModel)]="documentFilter.journalPayroll">
                <option *ngFor="let option of journalPayrollList" [value]="option.value">{{option.name}}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="sequenceNameInput">Sequence Name</label>
              <input type="text" class="form-control" name="sequenceNameInput" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="documentFilter.sequenceName" maxlength="50">
            </div>
            <div class="form-group">
              <label for="creatorInput">Creator</label>
              <input type="text" class="form-control" name="creatorInput" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="documentFilter.createdBy" maxlength="50">
            </div>
            <div class="form-group">
              <label for="approverInput">Approver</label>
              <input type="text" class="form-control" name="approverInput" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="documentFilter.approver" maxlength="50">
            </div><div class="form-group">
              <label for="partnerNameInput">Partner Name</label>
              <input type="text" class="form-control" name="partnerNameInput" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="documentFilter.partnerName" maxlength="50">
            </div>

            <div *ngIf="errorMessage" class="alert alert-warning"><i-feather name="alert-triangle"></i-feather>&nbsp;{{errorMessage}}</div>

            <ng-template [ngIf]="!submitting">
              <button (click)="onReset($event)" class="btn btn-outline-primary mr-2">Reset</button>
            </ng-template>
            <ng-template [ngIf]="submitting">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Retrieving documents...</span>
              </div>
              Retrieving documents...
            </ng-template>

            <ng-template [ngIf]="!submitting">
              <button type="submit" class="btn btn-success">Next</button>
            </ng-template>

          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header" id="headingTwo">
          <button class="btn btn-link disabled" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" (click)="handleCollapse()">
            <i-feather name="truck"></i-feather>&nbsp;&nbsp;All
          </button>
        </div>
        <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
          <div class="card-body">

            <p class="card-text">Review all approvals</p>

            <div class="p5">
              <div *ngIf="errorMessage" class="alert alert-warning"><i-feather name="alert-triangle"></i-feather>&nbsp;{{errorMessage}}</div>

              <ng-template [ngIf]="submitting">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Retrieving documents...</span>
                </div>
                Retrieving documents...
              </ng-template>

              <ng-template [ngIf]="!submitting">
                <button type="button" (click)="onReviewAll($event)" class="btn btn-success">Next</button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
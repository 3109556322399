import { Component, Input, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AccordionItem } from '../../models/accordion';
import { FeatherIcons } from '../../models/constants';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss'
})
export class AccordionComponent {

  icons = FeatherIcons;

  private _data: Array<AccordionItem>;
  get data() { return this._data; }

  @Input() 
  set data(items: Array<AccordionItem>) {
    for (let i = 0; i < items.length; ++i) {
      items[i].headerId = `header${i}`;
      items[i].bodyId = `body${i}`;
    }

    this._data = items;
  }

  constructor(@Inject(DOCUMENT) private document: Document) { }

  toggleArrow(item: AccordionItem) {
    // Reset all icons
    this.data.forEach((x) => x.arrow = this.icons.ChevronDown );

    // Set active icon
    const content = this.document.getElementById(item.bodyId);
    setTimeout(() => 
      item.arrow = content.classList.contains('show') ? this.icons.ChevronRight : this.icons.ChevronDown, 
      400);
  }
}

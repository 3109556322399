<div class="container-fluid mt-3 mb-3">
  <h4>Approvals Complete</h4>
  <p>{{ this.updateResults.length }} item(s)</p>

  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Confirmed</th>
        <th scope="col">Description</th>
        <th scope="col">Net</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let doc of updateResults">
        <th scope="row">
          <ng-template [ngIf]="doc.succeeded">
            <i-feather name="check" style="color: var(--success);" >
            </i-feather>
            <span class="text-success mr-1">{{ doc.action }}</span>
          </ng-template>

          <ng-template [ngIf]="!doc.succeeded">
            <i-feather name="alert-octagon" style="color: var(--danger);" >
            </i-feather>
            <span class="text-danger mr-1">{{ doc.action }} failed</span>
          </ng-template>

        </th>
        <td>{{ doc.description }}</td>
        <td>{{ doc.net | currency }}</td>
      </tr>
    </tbody>
  </table>

  <ng-template [ngIf]="!this.anyUpdateFailed">
    <div class="alert alert-success" role="alert">
      <i-feather name="check" style="color: var(--success);" >
      </i-feather>
      All approvals processed
    </div>
  </ng-template>

  <ng-template [ngIf]="this.anyUpdateFailed">
    <div class="alert alert-danger" role="alert">
      <i-feather name="alert-octagon" style="color: var(--danger);" >
      </i-feather>
      Some approvals failed to process!
    </div>
  </ng-template>

  <button class="btn btn-primary" type="button" (click)="onOk()">Ok</button>
</div>

<nav class="navbar sticky-top navbar-dark shadow-sm p-1">
  <a href="#" routerLink="/documents" class="btn btn-sm btn-primary">
      <i-feather name="chevron-left"></i-feather> Back
  </a>
  Images for XYZ
</nav>

<div class="list-group m-2">
  <button type="button" class="list-group-item list-group-item-action active">
    <i-feather name="image" class="mr-2"></i-feather>Bill256434.jpg
  </button>
  <button type="button" class="list-group-item list-group-item-action"><i-feather name="folder" class="mr-2"></i-feather>Image Folder</button>
  <button type="button" class="list-group-item list-group-item-action"><i-feather name="file-text" class="mr-2 ml-3"></i-feather>DocumentNumber1.doc</button>
  <button type="button" class="list-group-item list-group-item-action"><i-feather name="image" class="mr-2 ml-3"></i-feather>MyImage.jpg</button>
  <button type="button" class="list-group-item list-group-item-action" disabled><i-feather name="file-text" class="mr-2"></i-feather>SupportingDocs.pdf</button>

  <div class="list-group m-0 ml-3">
    <button type="button" class="list-group-item list-group-item-action active">
      <i-feather name="image" class="mr-2"></i-feather>Bill256434.jpg
    </button>
    <button type="button" class="list-group-item list-group-item-action"><i-feather name="folder" class="mr-2"></i-feather>Image Folder</button>
    <button type="button" class="list-group-item list-group-item-action"><i-feather name="file-text" class="mr-2 ml-3"></i-feather>DocumentNumber1.doc</button>
    <button type="button" class="list-group-item list-group-item-action"><i-feather name="image" class="mr-2 ml-3"></i-feather>MyImage.jpg</button>
    <button type="button" class="list-group-item list-group-item-action" disabled><i-feather name="file-text" class="mr-2"></i-feather>SupportingDocs.pdf</button>
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { document } from "../../models/document";
import {
  trigger,
  style,
  animate,
  transition,
  state,
  stagger,
} from "@angular/animations";
import { DocumentsService } from "../../services/documents/documents.service";

@Component({
  selector: "app-document-item",
  templateUrl: "./document-item.component.html",
  styleUrls: ["./document-item.component.scss"],
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition(":enter, :leave", [
        //to void doesn't work
        animate(1000),
      ]),
    ]),

    trigger("completed", [
      state("true", style({ opacity: 0 })),
      state("false", style({ opacity: 1 })),
      transition("false => true", [animate(1000)]),
    ]),
  ],
})
export class DocumentItemComponent implements OnInit {
  @Input() doc: document;
  // @Output() itemClick = new EventEmitter()
  // @Output() itemSelect = new EventEmitter()
  // @Output() itemDeselect = new EventEmitter()

  @Output() approveClick = new EventEmitter();
  @Output() holdClick = new EventEmitter();
  @Output() rejectClick = new EventEmitter();
  @Output() commentClick = new EventEmitter();

  @Output() cardApproveClick = new EventEmitter();
  @Output() cardHoldClick = new EventEmitter();
  @Output() cardRejectClick = new EventEmitter();
  @Output() cardCommentClick = new EventEmitter();

  readonly APPROVAL_ACTION_NONE = " ";
  readonly APPROVAL_ACTION_DISAPPROVED = "X";
  readonly APPROVAL_ACTION_APPROVED = "A";
  readonly APPROVAL_ACTION_HOLD = "(";
  readonly APPROVAL_ACTION_NOHOLD = ")";
  readonly APPROVAL_ACTION_COMMENT = "L";

  constructor(public docsService: DocumentsService) {}

  ngOnInit() {}

  setBackground() {
    let myClasses = {
      approve: this.doc.action === "Approve",
      reject: this.doc.action === "Reject",
      hold: this.doc.action === "Hold",
      unhold: this.doc.action === "Un-Hold",
      comment: this.doc.action === "Comment",
    };
    return myClasses;
  }

  onCheckAreaClick(doc) {
    doc.selected = true;
  }

  onToggleExpand(doc) {
    doc.expanded = !doc.expanded;
  }

  // onCheckBoxChange(doc, isChecked) {
  //   console.log('Select: ' + doc.id + " " + isChecked)

  //   doc.selected = isChecked;

  //   if(isChecked) {
  //     this.itemSelect.emit(doc);
  //   } else {
  //     this.itemDeselect.emit(doc);
  //   }
  // }

  // onItemClick(doc) {
  //   console.log('Item Click: ' + doc.id)
  //   this.itemClick.emit(doc);
  // }

  onApproveClick(doc) {
    if (doc.action === "Approve") {
      doc.action = "";
    } else {
      doc.action = "Approve";
    }

    if (doc.sel === this.APPROVAL_ACTION_APPROVED) {
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.sel = this.APPROVAL_ACTION_APPROVED;
    }

    console.log("C Approve: " + doc.id);
    console.log(doc);
    //this.cardApproveClick.emit(doc)
  }

  onHoldClick(doc) {
    if (doc.action === "Hold") {
      doc.action = "";
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.action = "Hold";
      doc.sel = this.APPROVAL_ACTION_HOLD;
    }

    console.log("Hold: " + doc.id);
    //this.cardHoldClick.emit(doc)
  }

  onUnHoldClick(doc) {
    if (doc.action === "Un-Hold") {
      doc.action = "";
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.action = "Un-Hold";
      doc.sel = this.APPROVAL_ACTION_NOHOLD;
    }
  }

  onRejectClick(doc) {
    if (doc.action === "Reject") {
      doc.action = "";
    } else {
      doc.action = "Reject";
    }
    if (doc.sel === this.APPROVAL_ACTION_DISAPPROVED) {
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.sel = this.APPROVAL_ACTION_DISAPPROVED;
    }
    console.log("Reject: " + doc.id);
    //this.cardRejectClick.emit(doc)
  }

  onCommentClick(doc) {
    if (doc.action === "Comment") {
      doc.action = "";
    } else {
      doc.action = "Comment";
    }
    if (doc.sel === this.APPROVAL_ACTION_COMMENT) {
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.sel = this.APPROVAL_ACTION_COMMENT;
    }
    //doc.action = "Comment";

    console.log("Comment: " + doc.id);
    //this.cardCommentClick.emit(doc)
  }

  onDocLinkClick() {}
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { DocumentsService } from "../../services/documents/documents.service";
import { Client } from "../../models/client";
import { DocumentFilter } from "../../models/documentFilter";
import { ControlRoutes } from "../../models/constants";

@Component({
  selector: "app-approval",
  templateUrl: "./approval.component.html",
  styleUrls: ["./approval.component.scss"],
})
export class ApprovalComponent implements OnInit {
  @ViewChild("accordion") accordion!: ElementRef;
  errorMessage = "";
  timeoutMessage = "";
  documentFilter: DocumentFilter = this.getDefaultFilter();
  submitting = false;
  clients: Client[];

  journalPayrollList = [
    { name: "All Documents", value: `` },
    { name: "All Checks", value: `journal=2` },
    { name: "Regular Checks", value: `journal=2&pr_flag=N` },
    { name: "Paychecks", value: `journal=2&pr_flag=Y` },
    { name: "Depository Checks", value: `journal=2&pr_flag=D` },
    { name: "All Receipts", value: `journal=1&pr_flag=N` },
  ];

  constructor(private router: Router, private docService: DocumentsService) {}

  ngOnInit() {
    this.timeoutMessage = this.docService.documentTimoutMessage;

    this.docService.getClients().subscribe({
      next: (response) => (this.clients = response),
      error: (err) => console.log(err),
    });
  }

  onSubmit() {
    this.submitting = true;
    this.errorMessage = "";

    //this.docService.loadMockDocuments();

    this.loadDocuments(this.documentFilter);
  }

  onReviewAll(event: any) {
    event.preventDefault();
    this.submitting = true;
    this.errorMessage = "";
    this.loadDocuments(this.getDefaultFilter());
  }

  onReset(event: any) {
    event.preventDefault();
    this.documentFilter = this.getDefaultFilter();
  }

  private loadDocuments(documentFilter: DocumentFilter) {
    this.docService.loadDocuments(documentFilter).subscribe({
      next: (success) => {
        if (success) {
          this.router.navigateByUrl(ControlRoutes.Documents);
        }
      },
      error: (err) => {
        this.errorMessage =
          "Failed to retrieve documents. Please retry or login again.";
        this.submitting = false;
        console.log("loadDocumentsGrouped() failed");
        console.error(err);
      },
    });
  }

  private getDefaultFilter(): DocumentFilter {
    return {
      client: "",
      journalPayroll: "",
      sequenceName: "",
      createdBy: "",
      approver: "",
      partnerName: "",
    };
  }

  handleCollapse(): void {
    this.accordion.nativeElement.childNodes.forEach((child) => {
      // if current tab is already opened
      if (!child.childNodes[1].className.includes("show")) {
        child.childNodes[0].firstChild.className = "btn btn-link disabled"; // then disable tab
      } else {
        child.childNodes[0].firstChild.className = "btn btn-link collapsed";
      }
    });
  }
}

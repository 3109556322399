<div class="card mt-1 p-1" [ngClass]="setBackground()" [style.display]="doc.completed ? 'none' : ''" [@fade]>
    <div id="document-item" class="container-fluid w-100">
        <!-- d-flex -->
        <div class="row">
            <div class="pr-1">
              <ng-template [ngIf]="doc.isLocked">
                <i-feather name="lock" style="color: var(--dark);" >
                </i-feather>
                <span class="font-weight-normal">  {{ doc.conflict_user }}</span>
              </ng-template>
              <ng-template [ngIf]="doc.isOnHold">
                <i-feather name="pause-circle" style="color: var(--dark);" >
                </i-feather>
              </ng-template>

              <ng-template [ngIf]="!doc.isLocked && !doc.isOnHold">
                <ng-template [ngIf]="doc.action === 'Approve'">
                  <i-feather (click)="onApproveClick(doc)" name="thumbs-up" class="rounded" style="background-color: var(--success); color: white;" >
                  </i-feather>&nbsp;
                </ng-template>
                <ng-template [ngIf]="doc.action != 'Approve'">
                  <i-feather (click)="onApproveClick(doc)" name="thumbs-up" style="color: var(--success);" >
                  </i-feather>&nbsp;
                </ng-template>
              </ng-template>

            </div>

            <div class="col p-0">
                <!--  style="border: black solid 1px;" -->
                <div class="container-fluid w-100">
                    <div class="row">
                        <div class="col p-0">
                            <b>{{doc.description}}</b>
                        </div>
                        <!-- <div class="col p-0 text-truncate">
                            {{ doc.desc }}
                        </div> -->
                        <div class="col-5 p-0 text-right">
                            <b>{{ doc.net | currency }}</b>
                        </div>

                        <ng-template [ngIf]="!doc.isLocked">
                          <ng-template [ngIf]="doc.action === 'Reject'">
                            <div class="col-2 pl-4">
                              <i-feather (click)="onRejectClick(doc)" name="thumbs-down" class="rounded" style="background-color: var(--danger); color:white;" >
                              </i-feather>&nbsp;
                            </div>
                          </ng-template>
                          <ng-template [ngIf]="doc.action != 'Reject'">
                            <div class="col-2 pl-4">
                              <i-feather (click)="onRejectClick(doc)" name="thumbs-down" style="color: var(--danger);" >
                              </i-feather>&nbsp;
                            </div>
                          </ng-template>
                        </ng-template>


                    </div>
                    <div class="row">
                        <ng-template [ngIf]="!doc.expanded">
                            <div class="col p-0 text-truncate" style="width: 260px;">
                              {{doc.zen_journalname}} - {{ doc.full_reference }} - {{ doc.transaction_date | date:'shortDate'}}
                            </div>
                            <div class="col-3 mr-4 text-left">
                                <a [routerLink]="" (click)="onToggleExpand(doc)">more...</a>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="doc.expanded">
                            <div class="col p-0" style="width: 260px;">
                              <b>{{doc.zen_journalname}}</b> {{ doc.full_reference }}
                            </div>
                        </ng-template>
                    </div>

                    <div *ngIf="doc.expanded">

                        <div class="row mt-1">
                            <b>Date: </b> {{ doc.transaction_date | date:'shortDate'}}
                        </div>
                        <div class="row mt-1">
                          <b>Creator: </b> {{ doc.created_by }}
                        </div>
                        <div class="row mt-1">
                          <b>Created: </b>{{ doc.created | date:'short' }}
                        </div>

                        <!-- <div class="row mt-1">
                            <b>Info: </b>{{ doc.zen_journalname }}
                        </div> -->
                        <div class="row mt-1">
                            <div class="col p-0">
                                <b>Last Approver: </b>{{ doc.last_approver }}
                            </div>

                        </div>

                        <div class="row mt-1">
                          <div class="col p-0">
                              <b>Comment: </b>
                              <div class="form-group">
                                <!-- <label for="comment">Comments:</label> -->
                                <textarea name="comment" id="" cols="30" rows="1" class="form-control" [(ngModel)]="doc.comment"></textarea>
                            </div>
                          </div>

                          <!-- <div class="col-3 p-0 text-right">
                            <a [routerLink]="" (click)="onToggleExpand(doc)">less...</a>
                          </div> -->
                        </div>

                        <div class="row mt-3">
                            <div class="col col-12 p-0">

                            <ng-template [ngIf]="!doc.isLocked && !(doc.isOnHold && !doc.isCanRemoveHold)">

                              <ng-template [ngIf]="!doc.isOnHold">
                                <ng-template [ngIf]="doc.action === 'Hold'">
                                  <button class="btn btn-warning btn-sm" (click)="onHoldClick(doc)">
                                    <i-feather name="pause-circle" class="rounded" style="background-color: var(--warning); color: white;" >
                                    </i-feather>
                                  </button>
                                </ng-template>
                                <ng-template [ngIf]="doc.action != 'Hold'">
                                  <button class="btn btn-light btn-sm" (click)="onHoldClick(doc)">
                                    <i-feather name="pause-circle" style="color: var(--warning);" >
                                    </i-feather>
                                  </button>
                                </ng-template>
                              </ng-template>

                              <ng-template [ngIf]="doc.isOnHold">
                                <ng-template [ngIf]="doc.action === 'Un-Hold'">
                                  <button class="btn btn-warning btn-sm" (click)="onUnHoldClick(doc)">
                                    <i-feather name="play-circle" class="rounded" style="background-color: var(--warning); color: white;" >
                                    </i-feather>
                                  </button>
                                </ng-template>
                                <ng-template [ngIf]="doc.action != 'Un-Hold'">
                                  <button class="btn btn-light btn-sm" (click)="onUnHoldClick(doc)">
                                    <i-feather name="play-circle" style="color: var(--warning);" >
                                    </i-feather>
                                  </button>
                                </ng-template>
                              </ng-template>

                            </ng-template>

                            &nbsp;

                            <ng-template [ngIf]="!doc.isLocked && !(doc.isOnHold && !doc.isCanRemoveHold)">
                              <ng-template [ngIf]="doc.action === 'Comment'">
                                <button class="btn btn-primary btn-sm" (click)="onCommentClick(doc)">
                                  <i-feather name="edit3" class="rounded" style="background-color: var(--primary); color: white;" >
                                  </i-feather>
                                </button>
                              </ng-template>
                              <ng-template [ngIf]="doc.action != 'Comment'">
                                <button class="btn btn-light btn-sm" (click)="onCommentClick(doc)">
                                  <i-feather name="edit3" class="rounded" style="color: var(--primary);" >
                                  </i-feather>
                                </button>
                              </ng-template>
                            </ng-template>
                            &nbsp;

                            <!-- <i-feather name="image" style="color: var(--dark);" href="#" [routerLink]="['/documents', doc.docid, 'imageview']">
                            </i-feather> -->

                            <a [href]="this.docsService.buildImageURL(doc.docid, 1)" target="_blank" (click)="onDocLinkClick()">
                              <i-feather name="image" style="color: var(--dark);">
                              </i-feather>
                            </a>

                            <!-- <button class="btn btn-light btn-sm" href="#" routerLink="/images">
                              <i-feather name="image" class="rounded" style="color: var(--dark);" >
                              </i-feather>
                            </button> -->

                            <!-- <i-feather (click)="onCommentClick(doc)" name="edit3" style="color: var(--primary);"
                                aria-haspopup="true" aria-expanded="false" data-toggle="modal"
                                data-target="#commentModal">
                            </i-feather>&nbsp; -->
                            &nbsp;

                            <!-- <i-feather name="file-text" style="color: var(--dark);" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                            </i-feather>
                            <div class="dropdown-menu"> -->
                                <!-- <a class="dropdown-item" href="#" routerLink="/images">Image(s)</a> -->
                                <!-- <a class="dropdown-item" href="#" routerLink="/documents">Journal Report</a>
                                <a class="dropdown-item" href="#" routerLink="/documents">Entity History Report</a>
                            </div> -->

                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <span class="col-3 p-0 text-right">
                              <a [routerLink]="" (click)="onToggleExpand(doc)">
                                less...</a>
                            </span>

                        </div>


                    </div>

                </div>
            </div>


        </div> <!-- main row -->

    </div> <!-- document item -->
</div> <!-- card -->

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { document } from "../../models/document";
import {
  trigger,
  state,
  transition,
  animate,
  style,
} from "@angular/animations";
import { DocumentsService } from "../../services/documents/documents.service";

@Component({
  selector: "app-document-table",
  templateUrl: "./document-table.component.html",
  styleUrls: ["./document-table.component.scss"],
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition("void => *, * => void", [animate("500ms ease-in")]),
    ]),
  ],
})
export class DocumentTableComponent implements OnInit {
  @Input() documents: document[];
  @Output() itemClick = new EventEmitter();
  @Output() itemSelect = new EventEmitter();
  @Output() itemDeselect = new EventEmitter();

  @Output() approveClick = new EventEmitter();
  @Output() holdClick = new EventEmitter();
  @Output() rejectClick = new EventEmitter();
  @Output() commentClick = new EventEmitter();

  @Output() approveAllClick = new EventEmitter();
  @Output() rejectAllClick = new EventEmitter();
  @Output() holdAllClick = new EventEmitter();
  @Output() commentAllClick = new EventEmitter();

  readonly APPROVAL_ACTION_NONE = " ";
  readonly APPROVAL_ACTION_DISAPPROVED = "X";
  readonly APPROVAL_ACTION_APPROVED = "A";
  readonly APPROVAL_ACTION_HOLD = "(";
  readonly APPROVAL_ACTION_NOHOLD = ")";
  readonly APPROVAL_ACTION_COMMENT = "L";

  constructor(private docsService: DocumentsService) {}

  ngOnInit() {}

  onItemSelect(doc) {
    doc.selected = !doc.selected;
    //this.itemSelect.emit(doc);
  }

  onApproveClick(doc) {
    //console.log('Approve: ' + doc.id)
    //this.approveClick.emit(doc)

    if (doc.action === "Approve") {
      doc.action = undefined;
    } else {
      doc.action = "Approve";
    }

    if (doc.sel === this.APPROVAL_ACTION_APPROVED) {
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.sel = this.APPROVAL_ACTION_APPROVED;
    }
  }

  onHoldClick(doc) {
    console.log("Hold: " + doc.id);
    //this.approveClick.emit(doc)

    if (doc.action === "Hold") {
      doc.action = "";
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.action = "Hold";
      doc.sel = this.APPROVAL_ACTION_HOLD;
    }
  }

  onUnHoldClick(doc) {
    if (doc.action === "Un-Hold") {
      doc.action = "";
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.action = "Un-Hold";
      doc.sel = this.APPROVAL_ACTION_NOHOLD;
    }
  }

  onRejectClick(doc) {
    console.log("Reject: " + doc.id);
    //this.rejectClick.emit(doc)
    if (doc.action === "Reject") {
      doc.action = "";
    } else {
      doc.action = "Reject";
    }

    if (doc.sel === this.APPROVAL_ACTION_DISAPPROVED) {
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.sel = this.APPROVAL_ACTION_DISAPPROVED;
    }
  }

  onCommentClick(doc) {
    console.log("Comment: " + doc.id);
    //this.commentClick.emit(doc)
    if (doc.action === "Comment") {
      doc.action = "";
    } else {
      doc.action = "Comment";
    }

    if (doc.sel === this.APPROVAL_ACTION_COMMENT) {
      doc.sel = this.APPROVAL_ACTION_NONE;
    } else {
      doc.sel = this.APPROVAL_ACTION_COMMENT;
    }
  }

  onApproveAllClick() {
    this.approveAllClick.emit();
  }

  onRejectAllClick() {
    this.rejectAllClick.emit();
  }

  onHoldAllClick() {
    this.holdAllClick.emit();
  }

  onCommentAllClick() {
    this.commentAllClick.emit();
  }
}

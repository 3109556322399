import { FeatherIcons } from '../models/constants';

export class AccordionItem {
  title: string;
  text: string;
  arrow: string = FeatherIcons.ChevronDown;
  headerId: string;
  bodyId: string;

  public constructor(init?: Partial<AccordionItem>) {
    Object.assign(this, init);
  }
}

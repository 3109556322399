import { Component, OnInit } from "@angular/core";
import { DocumentsService } from "../../services/documents/documents.service";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { forEach } from "lodash";
import { ControlRoutes } from "../../models/constants";

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.scss"],
})
export class ResultsComponent implements OnInit {
  //public anyUpdateFailed:boolean;

  constructor(private docsService: DocumentsService, private router: Router) {
    console.log("results CONST");
    // this.docsService.updateResults.forEach((result) => {
    //   console.log(result);
    //   if(!result.succeeded) console.log("UPDATE FAIL");
    // });
    // console.log(_.findIndex(this.updateResults, ['succeeded', false]));
    // if(_.findIndex(this.updateResults, ['succeeded', false]) === -1) {
    //   let anyUpdateFailed = false;
    // } else {
    //   let anyUpdateFailed = true;
    // }
  }

  ngOnInit() {}

  get updateResults() {
    return JSON.parse(this.docsService.updateResults.toString());
  }

  get anyUpdateFailed() {
    return this.docsService.anyUpdateFailed;
  }
  // anyUpdateFailed() {
  //   console.log("FIND");
  //   console.log(this.updateResults);
  //   return false;

  //   console.log(_.findIndex(this.docsService.updateResults, ['succeeded', false]));

  //   if(_.findIndex(this.docsService.updateResults, ['succeeded', false]) === -1) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  onOk() {
    this.docsService.processDocs();
    this.router.navigateByUrl(ControlRoutes.Approval);
  }
}

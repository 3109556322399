import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "../app.component";
import { NavbarComponent } from "../components/navbar/navbar.component";
import { DocumentsComponent } from "../components/documents/documents.component";
import { DocumentItemComponent } from "../components/document-item/document-item.component";
import { AccordionComponent } from "../components/accordion/accordion.component";
import { IconsModule } from "./icons.module";
import { ToolbarComponent } from "../components/toolbar/toolbar.component";
import { LoginComponent } from "../components/login/login.component";
import { ImagesComponent } from "../components/images/images.component";
import { DocumentsService } from "../services/documents/documents.service";
import { DocumentTableComponent } from "../components/document-table/document-table.component";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApprovalComponent } from "../components/approval/approval.component";
import { SubmitComponent } from "../components/submit/submit.component";
import { ResultsComponent } from "../components/results/results.component";
import { DocumentsParentComponent } from "../components/documents-parent/documents-parent.component";
import { ImagesListComponent } from "../components/images-list/images-list.component";
import { ImageViewComponent } from "../components/image-view/image-view.component";
import { CookieService } from "ngx-cookie-service";
import { NgSelectModule } from "@ng-select/ng-select";
import { AuthenticationInterceptor } from "../interceptors/authentication.interceptor";
import { WhatsNewComponent } from "../components/whats-new/whats-new.component";

@NgModule({ 
    declarations: [
        AppComponent,
        NavbarComponent,
        DocumentsComponent,
        DocumentItemComponent,
        ToolbarComponent,
        LoginComponent,
        ImagesComponent,
        DocumentTableComponent,
        ApprovalComponent,
        SubmitComponent,
        ResultsComponent,
        DocumentsParentComponent,
        ImagesListComponent,
        ImageViewComponent,
        WhatsNewComponent,
        AccordionComponent
    ],
    bootstrap: [ AppComponent ], 
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        IconsModule,
        NgSelectModule
    ],
    providers: [
        DocumentsService,
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule {}

import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { ControlRoutes } from "src/app/models/constants";

import { AuthService } from "../services/auth/auth.service";

export const AuthGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isLoggedIn) {
    return true;
  }

  router.navigateByUrl(ControlRoutes.Login);
  return false;
};

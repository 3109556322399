import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DocumentsComponent } from "../components/documents/documents.component";
import { ImagesComponent } from "../components/images/images.component";
import { LoginComponent } from "../components/login/login.component";
import { ApprovalComponent } from "../components/approval/approval.component";
import { SubmitComponent } from "../components/submit/submit.component";
import { AuthGuard } from "../interceptors/auth.guard";
import { ResultsComponent } from "../components/results/results.component";
import { DocumentsParentComponent } from "../components/documents-parent/documents-parent.component";
import { ImageViewComponent } from "../components/image-view/image-view.component";
import { ControlRoutes } from "../models/constants";
import { WhatsNewComponent } from "../components/whats-new/whats-new.component";

const routes: Routes = [
  { path: ControlRoutes.Login, component: LoginComponent },
  { path: ControlRoutes.Approval, component: ApprovalComponent, canActivate: [AuthGuard] },
  { path: ControlRoutes.WhatsNew, component: WhatsNewComponent, canActivate: [AuthGuard] },
  {
    path: "documents", 
    component: DocumentsParentComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: "", component: DocumentsComponent },
      { path: "submit", component: SubmitComponent },
      { path: "results", component: ResultsComponent },
      { path: "images", component: ImagesComponent },
      { path: ":docid/imageview", component: ImageViewComponent },
    ],
  },
  { path: "**", component: ApprovalComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

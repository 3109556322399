<nav class="navbar sticky-top navbar-dark shadow-sm p-1">
  <a href="#" routerLink="/documents" class="btn btn-sm btn-primary">
      <i-feather name="chevron-left"></i-feather> Back
  </a>
  {{ imageName }}
</nav>
<div>
  <iframe id="imageFrame" name="imageFrame" [src]="this.imageUrl" class="iframeStyle" frameborder="0"></iframe>
  <!-- <object id="imageFrame" name="imageFrame" [data]="this.imageUrl" class="iframeStyle" frameborder="0"></object> -->
  <!-- <embed id="imageFrame" name="imageFrame" [src]="this.imageUrl" class="iframeStyle" frameborder="0"> -->

  <!-- <script>
    myframe.onload = function(){
      var that = document.getElementById('myframe');

      try{
          (that.contentWindow||that.contentDocument).location.href;
      }
      catch(err){
          //err:SecurityError: Blocked a frame with origin "http://*********" from accessing a cross-origin frame.
          console.log('err:'+err);
      }
    }
   </script> -->
</div>


import { Component, OnInit } from '@angular/core';
import { AccordionItem } from '../../models/accordion';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrl: './whats-new.component.scss'
})
export class WhatsNewComponent implements OnInit {

  accordionData: Array<AccordionItem>;

  constructor() { }

  ngOnInit(): void {
    this.accordionData = [
      new AccordionItem({
        title: 'Release version 1.2.0',
        text: `- Added What's New page
               - Security Updates 1.1`
      }),
      new AccordionItem({
        title: 'Release version 1.1.0',
        text: 'Security Updates 1.0'
      })
    ];
  }
}

export const ControlRoutes = {
  Login: "",
  Approval: "approval",
  Documents: "documents",
  WhatsNew: "whats-new",
  Submit: "documents/submit",
  Results: "documents/results",
  Images: "documents/images",
  ImageView: "documents/imageview",
};

export const FeatherIcons = {
  ChevronLeft: 'chevron-left',
  ChevronRight: 'chevron-right',
  ChevronDown: 'chevron-down'
}
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { ControlRoutes } from "../../models/constants";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  constructor(private authService: AuthService) {}

  routes = ControlRoutes;

  ngOnInit() {}

  UserName() {
    return this.authService.userName;
  }

  onLogout() {
    console.log("navbar logout");
    this.authService.logout();
  }
}

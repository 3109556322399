<div class="container-fluid mt-3">
  <h4>Confirm Approvals</h4>
  <p>{{ actionItems.length}} item(s)</p>

  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Action</th>
        <th scope="col">Description</th>
        <th scope="col">Net</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let doc of actionItems">
        <th scope="row">
          <ng-template [ngIf]="doc.action === 'Approve'">
            <i-feather name="thumbs-up" style="color: var(--success);" >
            </i-feather>
            <!-- <span class="text-success mr-1">{{ doc.action }}</span> -->
          </ng-template>

          <ng-template [ngIf]="doc.action === 'Hold'">
            <i-feather name="pause-circle" style="color: var(--warning);" >
            </i-feather>
            <!-- <span class="text-warning mr-1">{{ doc.action }}</span> -->
          </ng-template>

          <ng-template [ngIf]="doc.action === 'Un-Hold'">
            <i-feather name="play-circle" style="color: var(--warning);" >
            </i-feather>
            <!-- <span class="text-warning mr-2">{{ doc.action }}</span> -->
          </ng-template>

          <ng-template [ngIf]="doc.action === 'Reject'">
            <i-feather name="thumbs-down" style="color: var(--danger);" >
            </i-feather>
            <!-- <span class="text-danger mr-2">{{ doc.action }}</span> -->
          </ng-template>

          <ng-template [ngIf]="doc.action === 'Comment'">
            <i-feather name="edit3" style="color: var(--primary);" >
            </i-feather>
            <!-- <span class="text-primary mr-2">{{ doc.action }}</span> -->
          </ng-template>

        </th>
        <td>{{ doc.description }}</td>
        <td>{{ doc.net | currency }}</td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="errorMessage" class="alert alert-danger"><i-feather name="alert-triangle"></i-feather>&nbsp;{{errorMessage}}</div>
  <div class="mb-3">
    <ng-template [ngIf]="submitting">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Submitting Approvals...</span>
      </div>
      Submitting Approvals
    </ng-template>

    <ng-template [ngIf]="!submitting">
      <form #form="ngForm" class="form-inline" (ngSubmit)="onSubmit()" novalidate>
        <div class="form-group mr-2">
          <!-- <label for="password">Password</label> -->
          <input type="password" id="password" name="password" class="form-control" placeholder="Confirm Password" required [(ngModel)]="confirmPassword" #password="ngModel">
        </div>
        <div>
          <button class="btn btn-success mr-2" type="submit" [disabled]="form.invalid">Submit</button>
          <button class="btn btn-primary" type="button" (click)="onCancel()">Cancel</button>
        </div>
      </form>
    </ng-template>
  </div>
</div>
